import React, { useEffect } from "react";
import { useAppState } from "@state";
import loadable from "@loadable/component";
import PageContainer from "@layout/DefaultPageContainer";

const Cta = loadable(() => import("@organisms/Cta"));
const NewsletterCta = loadable(() => import("@organisms/NewsletterCta"));
const PageBuilder = loadable(() =>
  import("../components/templates/PageBuilder")
);

const PagesDefault = ({ data, pageContext }) => {
  const { blocks, meta, hideCta, hideForm } = data;
  const { pageTheme } = pageContext;

  const [, dispatch] = useAppState();
  useEffect(() => {
    if (pageTheme === "dark") {
      dispatch({ type: "setThemeDark" });
    } else {
      dispatch({ type: "setThemeLight" });
    }
  }, []);

  const topBg =
    blocks.at(-1)?.bgColor === "white" || !blocks.at(-1)?.bgColor
      ? "white"
      : "midnight";

  return (
    <PageContainer meta={meta}>
      <PageBuilder blocks={blocks} />
      {!hideCta && <Cta topBg={topBg} />}
      {!hideForm && <NewsletterCta paddingBottom />}
    </PageContainer>
  );
};

PagesDefault.defaultProps = {};

export default PagesDefault;
